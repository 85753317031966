import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {NavDropdown} from "react-bootstrap";
import {useCookies} from "react-cookie";
import {Link, useNavigate} from "react-router-dom";

import Pdf from "../documents/guia-siniestros-dgt-2024-1.pdf";

export default function Menu() {

	const [cookies, setCookie, removeCookie] = useCookies(['token','user']);
	const navigate = useNavigate();

	const logout = async () => {
		removeCookie('token');
		navigate("/login");
	};
	const handleFilteredPdf = (event) => {
		event.preventDefault();
		if(window.confirm('Generali exije que toda la tramitación referente a los siniestros se tiene que efectuar a través de los teléfonos y correos electrónicos que figuran en esta guía')) {
			window.open(Pdf);
		}
	};

	return (
		<>
			<Navbar bg="primary" data-bs-theme="dark">
				<Container>
					<Navbar.Brand href="/" className="me-5"><img className="me-3" src="/logo-dgt-mya-horizontal.png" />Muñiz y Asociados - DGT App</Navbar.Brand>
					<Nav className="d-block w-100">
						<Nav.Link href="/" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Inicio</Nav.Link>
						{ cookies.user && cookies.user.admin === 1 ?
							<>
								<Nav.Link href="/certificate/create" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Alta nueva</Nav.Link>
								<Nav.Link href="/certificate/importdown" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Bajas</Nav.Link>
							</>
						: ''}
						<Nav.Link href="" onClick={handleFilteredPdf} style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Guía de Siniestros</Nav.Link>
						{ cookies.user && cookies.user.id === 1 ?
							<Nav.Link href="/certificate/manage" style={{float:"left",color:"var(--bs-nav-link-hover-color)"}}>Histórico</Nav.Link>
							: ''}
						<NavDropdown title={'Usuario'} id="collasible-nav-dropdown" className="text-capitalize" style={{float:"right"}}>
							<NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
						</NavDropdown>
						<img className="me-1 mt-1" src="/user.png" style={{float:"right"}} />
					</Nav>
				</Container>
			</Navbar>
		</>
	);
}