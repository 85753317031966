import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useCookies } from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import axios from 'axios';

export default function Login() {

	const [ user, setUser ] = useState("");
	const [ password, setPassword ] = useState("");
	const [cookies, setCookie, removeCookie] = useCookies(['token','user']);
	const navigate = useNavigate();


	useEffect(()=>{
		if(cookies.token){
			navigate("/");
		}
	},[]);

	const login = async (e) => {
		e.preventDefault();

		const raw = {
			'user': user,
			'password': password
		};

		await axios.post("https://api-dt.seguronline.es/user/login", raw).then(({data})=>{
			if(data){
				setCookie('token',data.token);
				setCookie('user',data.user);
				navigate("/");
			}else{
				alert('Usuario y/o contraseña incorrectos');
			}
		}).catch(({response:{data}})=>{
			alert("Error de acceso.");
		});

	};

	return (
		<>
			<br/><br/><br/>
			<Container className="col-sm-3 shadow-lg p-3 mb-5 bg-white rounded text-center p-md-5 width480">
				<img src="/logo-renfe-dgt.png" />
				<Form onSubmit={login}>
					<br/><br/>
					<Form.Group controlId="companyRef">
						<Form.Label>Usuario</Form.Label>
						<Form.Control type="text" value={user} onChange={(event) => {
							setUser(event.target.value);
						}}/>
					</Form.Group>
					<br/>
					<Form.Group controlId="companyRef">
						<Form.Label>Contraseña</Form.Label>
						<Form.Control type="password" value={password} onChange={(event) => {
							setPassword(event.target.value);
						}}/>
					</Form.Group>
					<br/>
					<Button variant="primary" className="mt-2" size="md" block="block" type="submit">
						Iniciar sesión
					</Button>
				</Form>
			</Container>
			<br/><br/><br/>
		</>
	);
}