import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link,useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ReactPaginate from "react-paginate";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
export default function CertificateIndex() {

	const [ certificates, setCertificates ] = useState(null);
	const [ totalItems, setTotalItems ] = useState(0);
	const itemsPerPage = 30;
	const navigate = useNavigate();
	const [cookies, setCookie, removeCookie] = useCookies(['token','user']);
	const [searchValue, setSearch] = useState("");
	const [stateValue, setStateValue] = useState("");
	const [dgtValue, setDgtValue] = useState("");
	const [loading, setLoading] = useState("");

	const pageCount = Math.ceil(totalItems / itemsPerPage);

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}else{
			fetchCertificates(0);
		}
	},[]);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % totalItems;
		if((searchValue && searchValue.length >= 3) || stateValue || dgtValue ) {
			fetchCertificates(newOffset,searchValue,stateValue,dgtValue);
		}else{
			fetchCertificates(newOffset);
		}

	};

	const handleState = (event) => {
		event.preventDefault();

		let state =  event.target.value;

		setStateValue(state);
		fetchCertificates(0,searchValue,state,dgtValue);

	};

	const handleDgt = (event) => {
		event.preventDefault();

		let dgt =  event.target.value;

		setDgtValue(dgt);
		fetchCertificates(0,searchValue,stateValue,dgt);

	};

	const handleSearch = (event) => {
		event.preventDefault();

		let search =  event.target.value;

		setSearch(search);
		fetchCertificates(0,search,stateValue,dgtValue);

	};

	const handleExcel = (event) => {
		event.preventDefault();

		fetchCertificatesExcel(searchValue,stateValue,dgtValue);

	};


	const handleFilteredPdf = (event) => {
		event.preventDefault();

		fetchCertificatesFilteredPdf(searchValue,stateValue,dgtValue);

	};

	const handlePrintPdf = async (event) => {

		await axios({
			url: 'https://api-dt.seguronline.es/certificate/pdf/'+event.target.value,
			method: 'GET',
			responseType: 'blob', // important
			headers: {Authorization: `${cookies.token}`}
		}).then(({data})=>{
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			let name = 'expediente';
			if(event.target.dataset.ref){
				name = event.target.dataset.ref;
			}
			link.href = url;
			link.setAttribute('download', name+'.pdf');
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la generación del PDF");
		});

	};

	const handlePrintPdfDown = async (event) => {

		await axios({
			url: 'https://api-dt.seguronline.es/certificate/pdf/down/'+event.target.value,
			method: 'GET',
			responseType: 'blob', // important
			headers: {Authorization: `${cookies.token}`}
		}).then(({data})=>{
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			let name = 'expediente';
			if(event.target.dataset.ref){
				name = event.target.dataset.ref;
			}
			link.href = url;
			link.setAttribute('download', name+'.pdf');
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la generación del PDF");
		});

	};

	const fetchCertificatesExcel = async (search,state,dgt) => {
		await axios.post("https://api-dt.seguronline.es/certificate/excel" , {
			"search": search,
			"state": state,
			"dgt": dgt,
			"fecha_inicio": '',
			"fecha_fin": '',
		},{
			responseType: 'blob', // important
			headers: {
				Authorization: `${cookies.token}`
			}
		}).then(({data}) => {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			let name = 'certificados';
			link.href = url;
			link.setAttribute('download', name+'.xlsx');
			document.body.appendChild(link);
			link.click();
		}).catch(({response:{data}})=>{
			alert("Error en la generación del Excel");
		});
	};

	const fetchCertificates = async (offset,search,state,dgt) => {
		if((search && search.length >= 3) || state || dgt ) {
			await axios.post("https://api-dt.seguronline.es/certificate/filter/" + offset, {
				"search": search,
				"state": state,
				"dgt": dgt,
				"fecha_inicio": '',
				"fecha_fin": '',
			},{
				headers: {
					Authorization: `${cookies.token}`
				}
			}).then(({data}) => {
				setCertificates(data.certificate);
				setTotalItems(data.totalItems);
			});
		}else{
			await axios.get("https://api-dt.seguronline.es/certificate/paginate/" + offset, {
				headers: {Authorization: `${cookies.token}`}
			}).then(({data}) => {
				setCertificates(data.certificate);
				setTotalItems(data.totalItems);
			});
		}

	};

	const fetchCertificatesFilteredPdf = async (search,state,dgt) => {

		const answer = window.confirm("¿Seguro que quiere descargar todos los certificados?");

		if (answer) {
			setLoading(true);
			await axios.post(
				"https://api-dt.seguronline.es/certificate/pdf" , {
					"search": search,
					"state": state,
					"dgt": dgt,
				},{
					responseType: 'blob', // important
					timeout: 300000,
					headers: {
						Authorization: `${cookies.token}`
					}
				}).then(({data})=>{
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'certificados.zip');
				document.body.appendChild(link);
				link.click();
			}).catch(({response:{data}})=>{
				alert("Error en la generación del ZIP");
			});
			setLoading(false);
		}
	};


	return (
		<Container>
			<br/>
			<h1>Lista de Certificados</h1>

			<Container>
				<Row>
					<Col>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Buscar:</Form.Label>
							<input placeholder="Búsqueda rápida" className="form-control" type="search" name="search"
								   value={searchValue || ""} onChange={handleSearch} onKeyUp={(e) => {
								if ((e.keyCode === 8 || e.keyCode === 46) && e.target.value === "") {
									setTimeout(() => {
										handleSearch(e)
									}, 750);
								}
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Estado:</Form.Label>
							<Form.Select onChange={handleState} >
								<option value="">Todos...</option>
								<option value="1">Dados de alta</option>
								<option value="2">Dados de baja</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="state">
							<Form.Label>Centro de trabajo:</Form.Label>
							<Form.Select onChange={handleDgt} >
								<option value="">Todos...</option>
								<option value="DGT">DGT</option>
								<option value="ATGC">ATGC</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col style={{paddingTop:'8px'}}>
						<br/>
						<Button variant="primary" onClick={handleExcel}>Descargar Excel</Button>
					</Col>
					<Col style={{paddingTop:'8px'}}>
						<br/>
						{loading ? <Spinner animation="border" variant="primary"/> :
							<Button variant="primary" onClick={handleFilteredPdf}>Descargar PDFs</Button>}
					</Col>
					<Col style={{paddingTop:'17px'}}>
						<div style={{marginTop: "6px"}}>Total <br />certificados: {totalItems}</div>
					</Col>
				</Row>
			</Container>
			<Container className="text-center">

			</Container>
			<br />
			<Table striped hover responsive
				   variant="light">
				<thead>
					<tr>
						<th>Colectivo</th>
						<th>Marca</th>
						<th>Modelo</th>
						<th>Version</th>
						<th>Matrícula</th>
						<th>Prima/Extorno</th>
						<th>Estado póliza</th>
						<th>Fecha efecto</th>
						<th>Fecha baja</th>
						<th>Certificado</th>
					</tr>
				</thead>
				<tbody>
				{certificates ? certificates.map((certificate, index) => (



						certificate.baja ?
							<tr key={index}>
								<td>{certificate.colectivo}</td>
								<td>{certificate.marca}</td>
								<td>{certificate.modelo}</td>
								<td>{certificate.version}</td>
								<td>{certificate.matricula}</td>
								<td>{certificate.extorno > 0 ? '-' + certificate.extorno : certificate.importe}€</td>
								<td>{certificate.baja ? 'Baja' : 'Alta'}</td>
								<td>{certificate.fecha_efecto}</td>
								<td>{certificate.fecha_baja}</td>
								<td>{certificate.fecha_baja ? <Button data-ref={certificate.matricula} value={certificate.id} size="sm"
																	  variant="primary" onClick={handlePrintPdfDown}>Imprimir
									Baja</Button> : '' }</td>
							</tr>
							:
							<tr key={index}>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.colectivo}</Link>
								: certificate.colectivo }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
								  to={"/certificate/" + certificate.id}>{certificate.marca}</Link>
									: certificate.marca }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.modelo}</Link>
									: certificate.modelo }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.version}</Link>
									: certificate.version }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.matricula}</Link>
									: certificate.matricula }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.extorno > 0 ? '-' + certificate.extorno : certificate.importe}€</Link>
									: <>{certificate.extorno > 0 ? '-' + certificate.extorno : certificate.importe}€</> }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.baja ? 'Baja' : 'Alta'}</Link>
									: <>{certificate.baja ? 'Baja' : 'Alta'}</> }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
										  to={"/certificate/" + certificate.id}>{certificate.fecha_efecto}</Link>
									: certificate.fecha_efecto }</td>
								<td>{ cookies.user && cookies.user.admin === 1 ? <Link className="text-primary-emphasis"
								  to={"/certificate/" + certificate.id}>{certificate.fecha_baja}</Link>
									: certificate.fecha_baja }</td>
								<td><Button data-ref={certificate.person_number} value={certificate.id} size="sm"
										variant="primary" onClick={handlePrintPdf}>Imprimir
								PDF</Button></td>
							</tr>
				)) : <tr>
					<td colSpan={12}>Cargando</td>
				</tr>}
				</tbody>
			</Table>
			<br/>
			<Container>
				<ReactPaginate
					breakLabel="..."
					nextLabel="siguiente >"
					onPageChange={handlePageClick}
					pageRangeDisplayed={10}
					pageCount={pageCount}
					previousLabel="< anterior"
					marginPagesDisplayed={2}
					pageClassName="page-item text-primary-emphasis"
					pageLinkClassName="page-link text-primary-emphasis"
					previousClassName="page-item text-primary-emphasis"
					previousLinkClassName="page-link text-primary-emphasis"
					nextClassName="page-item text-primary-emphasis"
					nextLinkClassName="page-link text-primary-emphasis"
					breakClassName="page-item text-primary-emphasis"
					breakLinkClassName="page-link text-primary-emphasis"
					containerClassName="pagination text-primary-emphasis"
					activeClassName="active text-primary-emphasis"
					renderOnZeroPageCount={null}
				/>
			</Container>
		</Container>


	);
}