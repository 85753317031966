import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function CertificateCreate() {

	const today = (new Date()).getFullYear() + '-' + (((new Date()).getMonth() + 1) < 10 ? '0' + ((new Date()).getMonth() + 1).toString() :  (new Date()).getMonth() + 1  ) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate());
	const {id} = useParams();

	const [ matricula, setMatricula ] = useState("");
	const [ matricula_civil, setMatriculaCivil ] = useState("");
	const [ colectivo, setColectivo ] = useState("");
	const [ marca, setMarca ] = useState("");
	const [ modelo, setModelo ] = useState("");
	const [ version, setVersion ] = useState("");
	const [ codigo, setCodigo ] = useState("");
	const [ fechaEfecto, setFechaEfecto ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ regDate, setRegDate ] = useState(today);
	const [ capitals, setCapitals ] = useState(null);

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
	},[]);


	const fetchCapitals = async (colectivo) => {

		if(colectivo === 'DGT' || colectivo === 'ATGC' ) {
			await axios.get(`https://api-dt.seguronline.es/capital/${colectivo}`, {
				headers: {
					Authorization: `${cookies.token}`
				}
			}).then(({data}) => {

				setCapitals(data);
			});
		}else{
			setCapitals(null);
			setCodigo("");
		}
	};

	const createCertificate = async (e) => {
		e.preventDefault();

		const raw = {
			'colectivo': colectivo,
			'marca': marca,
			'modelo': modelo,
			'version': version,
			'matricula': matricula,
			'matricula_civil': matricula_civil,
			'fecha_expedicion': regDate,
			'codigo': codigo,
			'fecha_efecto': fechaEfecto,
			'email': email,
		};

		await axios.post(`https://api-dt.seguronline.es/certificate`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
				alert("Certificado dado de alta correctamente");
				navigate(`/`);

		}).catch(({response:{data}})=>{
			alert("Error al dar de alta el certificado. Datos incorrectos");
		});
	};
	const handleMassive  = async (e) => {
		navigate("/certificate/import");
	};

	return (
		<Container>
			<br/>
			<Row>
				<Col>
					<h2>Dar de alta un vehículo</h2>
				</Col>
				<Col>
					<Button style={{float:"right"}} className="text-center" variant="secondary" onClick={handleMassive}>Ir a carga masiva de datos</Button>
				</Col>
			</Row>
			<br/>
			<br/>
			<br/>
			<Form onSubmit={createCertificate}>
				<Row>
					<Col>
						<Form.Group controlId="marca">
							<Form.Label>Marca:</Form.Label>
							<Form.Control type="text" value={marca} onChange={(event) => {
								setMarca(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="modelo">
							<Form.Label>Modelo:</Form.Label>
							<Form.Control type="text" value={modelo} onChange={(event) => {
								setModelo(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="version">
							<Form.Label>Versión:</Form.Label>
							<Form.Control type="text" value={version} onChange={(event) => {
								setVersion(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="matricula">
							<Form.Label>Matricula:</Form.Label>
							<Form.Control type="text" value={matricula} onChange={(event) => {
								setMatricula(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="matriculacivil">
							<Form.Label>Matricula civil:</Form.Label>
							<Form.Control type="text" value={matricula_civil} onChange={(event) => {
								setMatriculaCivil(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="colectivo">
							<Form.Label>Colectivo:</Form.Label>
							<Form.Select value={colectivo} onChange={(event) => {
								setColectivo(event.target.value);
								fetchCapitals(event.target.value);
							}}>
								<option value="">Selecciona...</option>
								<option value="ATGC">ATGC</option>
								<option value="DGT">DGT</option>
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="name">
							<Form.Group controlId="capitals">
								<Form.Label>Capitales:</Form.Label>
								<Form.Select value={codigo} onChange={(event) => {
									setCodigo(event.target.value);
								}}>
									<option value=''> {capitals ? 'Selecciona...' : 'Selecciona un colectivo...'}</option>
									{capitals ? capitals.map((capital, index) => (
										<option value={capital.codigo}>{capital.tipologia}</option>
									)) : ''}
								</Form.Select>
							</Form.Group>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="email">
							<Form.Label>Email:</Form.Label>
							<Form.Control type="text" value={email} onChange={(event) => {
								setEmail(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dateDown">
							<Form.Label>Fecha de efecto:</Form.Label>
							<Form.Control type="date" min={'2024-08-21'} value={fechaEfecto} onChange={(event) => {
								setFechaEfecto(event.target.value);
							}}/>
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Row>
					<Col>
						<Form.Group controlId="dateDown">
							<Form.Label>Fecha de expedicion:</Form.Label>
							<Form.Control type="date" value={regDate} disabled={true}/>
						</Form.Group>
					</Col>
					<Col>
						<br/>
						<Button variant="primary" className="mt-2" size="md" block="block" type="submit">
							Dar de alta
						</Button>
					</Col>
					<Col>
					</Col>
				</Row>
			</Form>
			<br/>
			<br/>
		</Container>
	);
}