import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useCookies} from "react-cookie";

export default function CertificateDown() {

	const today = (new Date()).getFullYear() + '-' + (((new Date()).getMonth() + 1) < 10 ? '0' + ((new Date()).getMonth() + 1).toString() :  (new Date()).getMonth() + 1  ) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate());
	const {id} = useParams();

	const [ matricula, setMatricula ] = useState("");
	const [ codigo, setCodigo ] = useState("");
	const [ fechaEfecto, setFechaEfecto ] = useState("");
	const [ date, setDate ] = useState("");
	const [ regDate, setRegDate ] = useState(today);

	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const navigate = useNavigate();

	useEffect(()=>{
		if(!cookies.token){
			navigate("/login");
		}
		fetchCertificate();
	},[]);


	const fetchCertificate = async () => {
		await axios.get(`https://api-dt.seguronline.es/certificate/find/${id}`,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{

			if(data[0].baja){
				navigate("/");
			}

			setMatricula(data[0].matricula);
			setCodigo(data[0].codigo);
			setFechaEfecto(data[0].fecha_efecto);

		});
	};

	const downCertificate = async (e) => {
		e.preventDefault();

		const raw = {
			'fecha_baja': date,
			'fecha_registro_baja': regDate,
			'codigo': codigo,
			'fecha_efecto': fechaEfecto,
		};

		await axios.patch(`https://api-dt.seguronline.es/certificate/${id}`, raw,{
			headers: {
				Authorization: `${cookies.token}`
			}}).then(({data})=>{
				alert("Certificado dado de baja correctamente");
				navigate(`/`);

		}).catch(({response:{data}})=>{
			alert("Error al dar de baja el certificado. Datos incorrectos");
		});
	};

	/*const handleMassiveDown  = async (e) => {
		navigate("/certificate/importdown");
	};*/

	return (
		<Container>
			<br />
			<h2>Dar de baja un Certificado</h2>
			<br />
			{/* <Button className="text-center" variant="secondary" onClick={handleMassiveDown}>Ir a baja masiva de asegurados</Button> */}
			<br />
			<br />
			<Form onSubmit={downCertificate}>
				<Row>
					<Col>
						<Form.Group controlId="matricula">
							<Form.Label>Matricula:</Form.Label>
							<Form.Control disabled={true} type="text" value={matricula} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dateDown">
							<Form.Label>Fecha de baja del certificado:</Form.Label>
							<Form.Control type="date" value={date} onChange={(event) => {
								setDate(event.target.value);
							}}/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId="dateDown">
							<Form.Label>Fecha de registro de baja:</Form.Label>
							<Form.Control type="date" value={regDate} disabled={true} />
						</Form.Group>
					</Col>
				</Row>
				<br/>
				<Button variant="danger" className="mt-2" size="md" block="block" type="submit">
					Dar de baja
				</Button>
			</Form>
		</Container>
	);
}