import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Login from './components/Login'
import Footer from './components/Footer'
import Menu from './components/Menu'
import CertificateIndex from "./components/CertificateIndex";
import CertificateDown from "./components/CertificateDown";
import CertificateEdit from "./components/CertificateEdit";
import CertificateCreate from "./components/CertificateCreate";
import CertificateImport from "./components/CertificateImport";
import CertificateImportDown from "./components/CertificateImportDown";
import CertificateIndexManage from "./components/CertificateIndexManage";
import {Routes, Route} from "react-router-dom";
import { useCookies } from 'react-cookie';

function App() {

  const [cookies, setCookie, removeCookie] = useCookies(['token','user']);

  return (
      <>
        { cookies.token ? <Menu /> : ''}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<CertificateIndex />} />
            { cookies.user && cookies.user.admin === 1 ?
            <>
              <Route path="/certificate/down/:id" element={<CertificateDown />} />
              <Route path="/certificate/:id" element={<CertificateEdit />} />
              <Route path="/certificate/create" element={<CertificateCreate />} />
              <Route path="/certificate/import" element={<CertificateImport />} />
              <Route path="/certificate/importdown" element={<CertificateImportDown />} />
              <Route path="/certificate/manage" element={<CertificateIndexManage />} />
            </>
                : ''}
        </Routes>
        <Footer />
      </>
  );
}

export default App;
